import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Table from "../components/Table/Table"
import { StoreContext } from "../store"
import buildJobBoard from "../utilities/buildJobBoard"
import checkrSignupLink from "../utilities/checkrSignupLink"
import customerQuotes from "../utilities/customerQuotes"
import marketo from "../utilities/marketo"
import packagesTabs from "../utilities/packagesTabs"
import resourcesLoop from "../utilities/resourcesLoop"
import sectionBlocks from "../utilities/sectionBlocks"
import showMore from "../utilities/showMore"
import stateLawsArchive from "../utilities/stateLawsArchive"
import tableOfContentsSidebar from "../utilities/tableOfContentsSidebar"
import tabs from "../utilities/tabs"
import videoLinkModal from "../utilities/videoCtaLinkModal"
import vwo from "../utilities/vwo"

const Page = ({ data, location }) => {

  const setFooter = data.wpPage.headerAndFooter.pageFooterStyle

  const { id, content, headerAndFooter } = data.wpPage

  const isVwoPage = data?.wpPage.vwoFieldGroup?.vwoPage;

  // This is no longer relying on wpPages in settings. An ACF field now designates which pages get the script. 

  // const vwoPages =
  //   data.wp?.overviewHubspot?.hubspotSettings?.vwoScriptLoading?.vwoPages

  // let currentVWOPage = null

  // if (vwoPages !== null && vwoPages !== undefined) {
  //   currentVWOPage = vwoPages.filter(page => page.id === id)
  // }

  const { viewPortWidth, isAnnouncementActive } = React.useContext(StoreContext)

  const [isHubspot, setIsHubspot] = React.useState(false)
  const [isVwo, setIsVwo] = React.useState(false)

  React.useEffect(() => {
    sectionBlocks()
    if (isHubspot) {
      marketo()
    }
    customerQuotes()
    resourcesLoop()
    showMore()
    tabs()
    packagesTabs()
    stateLawsArchive()
    videoLinkModal()
    buildJobBoard()
    checkrSignupLink()
    tableOfContentsSidebar(
      window.document.querySelector(".toc-main-content-wrapper"),
      true
    )
    window.addEventListener("scroll", () => {
      tableOfContentsSidebar(
        window.document.querySelector(".toc-main-content-wrapper"),
        true
      )
    })

    if (
      typeof window !== "undefined" &&
      window.document
      // && window.document.querySelector(".HubspotApiBlock")
    ) {
      setIsHubspot(true)
    }

    if(isVwoPage !== null && isVwoPage === true) {
      setIsVwo(true)
    }

    // If the VWO checkbox is checked in WP, this returns true and runs the function (line 33)
    if (isVwoPage && isVwo && isHubspot) vwo()
    
  }, [isHubspot, isVwo]) 


  return (
    <Layout>
      <Seo pageData={data.wpPage} location={location} />

      <div className="page-template-default page wp-embed-responsive has-imageRight header-default footer-default customize-support js">
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header
            siteTitle={`GoodHire`}
            viewPortWidth={viewPortWidth}
            headerStyle={headerAndFooter.pageHeaderStyle}
            headerPartnerLogo={headerAndFooter.headerPartnerLogo}
          />

          {isVwoPage && isVwo ? (
            <div
              className="Page-content vwo-page"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          ) : (
            <div className="Page-content">
              {parse(content, {
                replace: domNode => {
                  if (
                    domNode &&
                    domNode.name === "table" &&
                    !domNode?.attribs?.class?.includes("no-filter") &&
                    !domNode?.parent?.attribs?.class?.includes("no-filter")
                  ) {
                    return <Table tableDomNode={domNode}></Table>
                  }
                },
              })}
            </div>
          )}

          {setFooter === null && <Footer />}

          {setFooter === "default" && <Footer />}

          {setFooter === "landing-page" && <FooterLandingPage />}

          {setFooter === "get-started" && <FooterMinimal />}
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      headerAndFooter {
        pageFooterStyle
        pageHeaderStyle
        headerPartnerLogo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
            extension
            publicURL
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      vwoFieldGroup {
        vwoPage
      }
      richSnippetSchemaJson
    }
  }
`
