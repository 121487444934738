/**
 * This script looks for checkr signup links (specified by the "checkr-signup-link" class), and ensures that
 * the current route's query params are passed to Checkr's signup page on navigation.
 * It also attaches a click function to those links that sends info to hubspot so that checkr knows how many users
 * and which specific users follow through to checkr's signup page.
 */

// getCookie function ported from hubspot.js
function getCookie(name) {
  //https://stackoverflow.com/questions/10730362/get-cookie-by-name
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
}

export default function checkrSignupLink() {
  if (typeof window !== "undefined" && window.document) {
    const checkrSignupLinkElements = document.querySelectorAll(
      ".checkr-signup-link a"
    )

    if (!checkrSignupLinkElements?.length) return

    const form = document.querySelector(".hs-form")

    // gather and build vars that are required to make API call to hubspot
    const guid = form?.dataset?.formId
    const portalId = form?.dataset?.portalId
    const hscookie = getCookie("hubspotutk")
    const submitUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${guid}`
    const queryParams = new URL(document.location).searchParams
    const emailParam = queryParams.get("email")
    // grab amplitude query param to determine if user is in experiment
    const abTestParam = queryParams.get("amplitudeExperimentBucket")

    /**
     * Update hrefs to pass queryParams to checkr signup page and set onclick
     * function to send info to hubspot
     */
    checkrSignupLinkElements.forEach(element => {
      if (element && !element.onclick) {
        // append route params to href
        // TODO: change this when we have to go to ab test live.
        if (!element.href.includes(window.location.search)) {
          if (abTestParam && !abTestParam.includes("control")) {
            queryParams.delete("amplitudeExperimentBucket")
            // if user is in treatment group redirect to test page
            element.href = `${process.env.GATSBY_AMPLITUDE_FOX_REDIRECT}?${queryParams.toString()}`
          } else {
            queryParams.delete("amplitudeExperimentBucket")
            element.href += `?${queryParams.toString()}`
          }

        }

        if (!!guid && !!portalId && !!emailParam) {
          // set onclick function so it sends user info to hubspot
          element.onclick = function (e) {
            // prevent navigation so we can send hubspot info first
            e.preventDefault()

            // build submission body for hubspot api call
            const submission = {
              fields: [{ name: "email", value: emailParam }],
              context: {
                pageUri: window.location.href,
                pageName: document.title,
                hutk: hscookie,
              },
              skipValidation: true,
            }

            // tell hubspot that the user followed through to the checkr signup page
            fetch(submitUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(submission),
            })
              .then(() => {
                // navigate
                window.location.assign(e.target.href)
              })
              .catch(() => {
                // navigate
                window.location.assign(e.target.href)
              })
          }
        }
      }
    })
  }
}
